@use '../../../styles/_global-variables.scss' as global;
@use '../../../styles/_portal-variables.scss' as tresplay;

.itemAudioWrapper {
    transition-property: height;
    margin-bottom: var(--gapRow);
    overflow: hidden;
    padding-top: 50px;
    margin-top: -50px;
    &.popup {
        max-width: 480px;
        padding: 0;
        margin: 0;
        .itemaudio {
            padding: 0;
            margin: 0;
            &:before,
            &:after {
                content: none;
            }
            :global {
                .videowrapper {
                    aspect-ratio: 3 / 2;
                    padding-top: 0;
                }
            }
        }
        .audio {
            padding-top: 0 !important;
            aspect-ratio: 3 / 2 !important;
            overflow: hidden;
        }
        .wrapperEntradeta {
            display: none;
        }
        .titolPrograma,
        .titol,
        .sharing {
            display: none;
        }
        .info {
            width: 100%;
            padding: 0;
            height: auto;
            top: 0;
            bottom: auto;
            left: 0;
            aspect-ratio: 3 / 2;
        }
        .directe .info {
            display: flex !important;
            flex-direction: row !important;
            padding-left: 16px !important;
            align-items: flex-start;
            gap: 8px;
            padding-top: 16px;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0) 80%, rgba(8, 8, 8, 0.6) 90%, rgba(8, 8, 8, 0.8) 100%);
        }
        .presentador {
            padding-top: 6px;
        }
        .audioFooter {
            bottom: 8px !important;
        }
        .durada {
            top: 0;
            bottom: 0;
        }
        .logoItemAudio {
            display: none;
        }
    }
}

.itemaudio {
    position: relative;
    max-width: 988px;
    margin: 0 auto;
    aspect-ratio: unset;
    padding: 0 56px 24px;
    :global {
        .F-mediaPrincipal {
            max-width: 988px;
            margin: 0 auto;
        }
        .no-imatge {
            display: none !important;
        }
        .videowrapper {
            aspect-ratio: unset;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        .ad-active {
            .videowrapper {
                padding-top: 0;
                z-index: 3;
                //aspect-ratio: 16 / 9;
                .jw-media {
                    background: #000;
                }
            }
            .bmpui-ui-controlbar {
                bottom: 0;
            }
            .bmpui-ui-controls-overlay {
                .bmpui-ui-rewindbutton,
                .bmpui-ui-playbacktogglebutton,
                .bmpui-ui-forwardbutton {
                    display: none !important;
                }
            }
            .bmpui-controlbar-bottom .bmpui-ui-playbacktogglebutton {
                display: none !important;
            }
            .bmpui-ui-hugeplaybacktogglebutton .bmpui-image {
                visibility: visible;
                display: block;
            }
        }
        .jwplayer.jwplayer.jw-skin-ccma .jw-button-container .jw-icon-playback {
            transform: scale(1);
        }
    }
    .info {
        padding: 0;
        width: calc(100% - 112px);
        padding-left: calc(50% - 36px);
        height: calc(100% + 108px);
    }
    &.ad .info {
        display: none;
    }
    .audio {
        position: relative;
        padding-top: calc((100% / 46 * 13) + 150px);
        aspect-ratio: unset;
    }
    .audioFooter {
        position: absolute;
        left: 0;
        bottom: 138px;
        height: 25px;
        pointer-events: all;
        width: 100%;
    }
    .titol {
        font-size: var(--headline_m);
    }
    .presentador {
        font-size: var(--body_l);
        font-weight: 500;
        margin-bottom: 18px;
    }
    .durada {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        top: 0;
        &.hide {
            display: none;
        }
    }
    &:before {
        content: '';
        display: block;
        width: 48%;
        height: 23%;
        border-radius: 50%;
        background-color: #e1261d;
        filter: blur(125px);
        position: absolute;
        top: 33%;
        left: -13%;
        opacity: 0.7;
    }
    &:after {
        content: '';
        display: block;
        width: 32%;
        height: 48%;
        border-radius: 50%;
        background-color: #e1261d;
        filter: blur(125px);
        position: absolute;
        top: 28%;
        right: -25%;
        opacity: 0.3;
    }
    &.directe {
        .info {
            padding-left: calc(32% + 20px);
        }
        .titol {
            margin-bottom: 16px;
        }
        .codiEdat {
            margin: 0;
            &:after {
                content: none;
            }
        }
    }
}

.noDisponible {
    width: calc(50% - 70px);
    background-color: var(--clr_trans_80);
    aspect-ratio: 16/9;
    position: absolute;
    .msgNoDisponible {
        background-color: var(--clr_portal_100);
        color: var(--clr_trans_0);
        padding: 8px 16px 5px;
        display: inline-block;
        margin-bottom: 15px;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .outertext {
            color: var(--clr_trans_0);
            font-size: var(--body_m);
            font-weight: 500;
            text-transform: uppercase;
            display: inline;
            margin-left: 15px;
        }
    }
}

.info {
    position: absolute;
    top: 0;
    width: 42%;
    height: 100%;
    pointer-events: none;
    padding: 150px 0 0 20px;

    &.hide {
        display: none;
    }
}

.afegirLlista,
.descarrega {
    pointer-events: all;
}

.peli {
    margin-bottom: 16px;
    font-size: var(--body_s);
    font-weight: 500;
    text-transform: uppercase;
    color: global.$color95;
    background-color: #fff;
    border-radius: 8px;
    padding: 5px 10px;
    display: inline-block;
}

.sharing {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-block: 8px 12px;
}
.logoCanal {
    display: flex;
    align-items: center;
    gap: 14px;
    margin-bottom: 40px;
    .ad & {
        display: none;
    }
}
.ad .presentador {
    display: none;
}
.logoDirecte {
    max-width: 118px;
    max-height: 32px;
}
.titol {
    font-size: var(--headline_l);
    font-weight: 300;
    text-shadow: 2px 2px rgb(0 0 0 / 45%);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.wrapperEntradeta {
    position: relative;
    padding: 0 56px;
    max-width: 988px;
    margin: 0 auto;
    z-index: 1;
    &.ad {
        display: none;
    }
}
.mobileSharing {
    display: none;
}
.entradeta {
    font-size: var(--body_m);
    line-height: 24px;
    font-weight: 400;
    overflow: hidden;
    margin-bottom: 16px;
    display: block;
    height: 72px;
    transition-property: all;
}
.codiEdat {
    display: inline-block;
    vertical-align: middle;
    margin: -5px 0 -7px;
}
.dataActualitzacio {
    display: inline-block;
    color: var(--clr_trans_20);
}
.slashVertical {
    &:before {
        content: '';
        display: inline-block;
        height: 16px;
        margin: 0px 12px -2px;
        width: 1px;
        background-color: var(--clr_trans_60);
    }
}
.mes {
    position: absolute;
    right: 56px;
    bottom: 0;
    height: 26px;
    width: 65px;
    text-align: right;
    background: linear-gradient(270deg, rgb(17, 17, 17) 45px, rgba(17, 17, 17, 0) 100%);
    display: none;
    cursor: pointer;
    span {
        border-bottom: 1px solid;
    }
}

.subinfo {
    display: flex;
    gap: 18px;
    align-items: center;
    padding-bottom: 16px;
}

.durada {
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: var(--body_s);
}

.continuacio {
    display: block;
    width: 100%;
    font-size: var(--body_s);
    text-align: center;
}

.despresTitol {
    font-weight: 700;
    margin-left: 5px;
}

.ico_continuacio {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
}

.ico_directe {
    width: 24px;
    height: 24px;
}

.pubdate {
    font-size: var(--body_s);
}

.ico_clock {
    height: 14px;
}

.logoItemAudio {
    max-height: 72px;
    max-width: 180px;
    margin-bottom: 16px;
    display: block;
    position: relative;
    height: 100%;

    img {
        margin: 0 !important;
        width: auto !important;
        min-width: auto !important;
    }
}

.botons,
.subtitols,
.boto {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.boto {
    padding: 0 4px;
    height: 20px;
    border: 1px solid #fff;
    border-radius: 4px;
    margin-right: 16px;
    margin-bottom: 16px;
    font-size: var(--body_xxs);
    pointer-events: all;
}

.txt_subtitols {
    font-size: var(--body_xs);
    line-height: 1.35;
    margin-left: 8px;
}

.sinopsi {
    padding: 16px 56px 0;
    margin-bottom: var(--gapRow);
    margin-top: calc(var(--gapRow) * -1);
}

.sinopsi_titol {
    font-weight: 300;
    font-size: var(--headline_xs);
    line-height: 1.4;
    text-transform: uppercase;
    margin-bottom: 16px;
}

.sinopsi_text p {
    line-height: 1.3;
    margin-bottom: 10px;
}

.titolPrograma {
    font-size: var(--headline_xs);
    font-weight: 700;
    margin-bottom: 16px;
    display: block;
}

// RMP

@media #{global.$VP_REGULAR_AND_TL} {
    //'(min-width: 1024px) and (max-width: 1279px)';
    .logoItemAudio {
        height: 100px;
    }
}

@media #{global.$VP_MOBILE_AND_TP} {
    // <1024
    .logoItemAudio {
        height: 80px;
    }
    .itemaudio {
        padding: 0 34px 24px;
        .info {
            width: calc(100% - 68px);
        }
    }
    .wrapperEntradeta {
        padding: 0 34px;
    }
    .mes {
        right: 34px;
    }
    .slashVertical {
        &:before {
            content: '';
            display: inline-block;
            height: 12px;
            margin: 0px 12px -1px;
            width: 1px;
            background-color: var(--clr_trans_60);
        }
    }
}

@media (max-width: 1090px) {
    .itemaudio .titol {
        -webkit-line-clamp: 2;
    }
}

@media #{global.$VP_LARGE_NEGATION} {
    .itemaudio {
        .titol {
            font-size: var(--headline_s);
        }
        :global {
            .videowrapper {
                padding-top: calc((100% / 46 * 13) + 130px);
            }
        }
    }
}

@media (max-width: 880px) {
    .itemaudio {
        padding: 0;
        margin: 0 auto 192px;
        position: relative;
        &.ad {
            overflow: hidden;
            aspect-ratio: 16 / 9;
            margin-bottom: 16px;
            :global {
                .videowrapper {
                    aspect-ratio: 16 / 9;
                }
            }
        }
        .info {
            position: absolute;
            bottom: -40px;
            top: auto;
            height: auto;
            padding-left: 0;
            text-align: center;
            left: 34px;
        }
        .ico_directe {
            width: 20px;
            height: 20px;
        }
        .durada {
            top: auto;
            bottom: -264px;
        }
        .logoItemAudio {
            margin: 0 auto 16px;
            img {
                margin: 0 auto !important;
            }
        }
        .sharing {
            display: none;
        }
        .audio {
            padding-top: 0;
            aspect-ratio: 9 / 7;
        }
        &:before {
            width: auto;
            height: 14%;
            background-color: #e1261d;
            top: auto;
            bottom: -160px;
            left: 0;
            right: 0;
            z-index: 0;
        }
        &:after {
            content: none;
        }
        &.directe {
            .info {
                padding-left: 0;
            }
            .logoCanal {
                justify-content: center;
                margin-bottom: 16px;
            }
            .titol {
                margin-bottom: 4px;
            }
            .audioFooter {
                bottom: -114px;
            }
            .audio {
                aspect-ratio: unset;
                padding-top: calc(100% / 840 * 437 + 90px);
            }
            :global {
                .videowrapper {
                    padding-top: 0;
                    aspect-ratio: unset;
                    position: absolute;
                }
            }
        }

        .noDisponible {
            width: 100%;
            aspect-ratio: 16/9;
        }
    }
    .logoCanal {
        gap: 6px;
    }
    .mobileSharing {
        display: flex;
        margin-left: 34px;
        &.ad {
            display: none;
        }
    }
}

@media (min-width: 881px) {
    .itemaudio.directe {
        :global {
            .videowrapper {
                padding-top: 0 !important;
            }
        }
        .audio {
            padding-top: calc((100% / 989 * 320) + 160px) !important;
        }
    }
}

@media #{global.$VP_ALL_MOBILE} {
    .itemaudio {
        padding: 0;
        .info {
            left: 12px;
            right: 12px;
            width: auto;
        }
        .titol {
            font-size: var(--headline_m);
        }
    }
    .wrapperEntradeta {
        padding: 0 12px;
    }
    .mes {
        right: 12px;
    }
    .mobileSharing {
        margin-left: 12px;
    }
    .titolPrograma {
        margin-bottom: 8px;
    }
}

@media (min-width: 1297px) {
    .info {
        padding-left: 59px;
    }
}
